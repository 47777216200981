// import Home from '@/pages/home';
import Loadable from '@/utils/loadable';
const Home = Loadable('home');
const Download = Loadable('download');
const Share = Loadable('share');
const Position = Loadable('position');
const Activity = Loadable('activity');
// const NotFound = Loadable('404');
export default [
  { path: '/', component: Home, exact: true },
  { path: '/download', component: Download, exact: false },
  { path: '/share', component: Share, exact: false },
  { path: '/position', component: Position, exact: false },
  { path: '/activity', component: Activity, exact: false },
];
